import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { createQuicksheet } from './quicksheet';
import classNames from 'classnames';
import { HEADERS } from 'constants/tech-spec';
import { unmountComponentAtNode } from 'react-dom';
import { CodeAndLabels } from 'components/quick-spec/code-and-labels';
import { Summary } from 'components/quick-spec/summary';
import { SpecBar } from 'components/quick-spec/spec-bar';
import { getValueFromDataObject } from 'modules/get-value-from-data-object';
import circularProductLogoWhite from '../../assets/images/circular_product_WM-white.png';
import styles from './style.module.scss';
import { useSelector } from 'react-redux';
import { find, filter, flatMap } from 'lodash';

export const QuickSpec = ({
  data,
  smallScreen,
  mainImage,
  productName,
  drawingOne,
  drawingTwo,
}) => {
  const [toggleSpec, setToggleSpec] = useState(false);
  const [closeSpec, setCloseSpec] = useState(false);
  const { activeEngine, data: productData } = useSelector(
    (state) => state.product
  );

  const { productDescription } = productData;

  const optiondescriptions = getValueFromDataObject(
    data.options,
    'description'
  );

  const { cri, circuit_power } =
    activeEngine.add_colour_temperature[0].add_lit_effect[0].add_beam_angle[0];

  const rawBeamAngle = data.beam_angles.label;

  const words = rawBeamAngle ? rawBeamAngle.split(' ') : [];

  const beamAngle = words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');

  const selectedColourTemp = find(activeEngine.add_colour_temperature, (o) => {
    // Remvove k's and spaces before comparing to align
    return (
      o.colour_temperature?.replaceAll(/k|\s/gi, '') ===
      data.colour_temperature.label?.replaceAll(/k|\s/gi, '')
    );
  });

  const beamAnglesFlat = flatMap(selectedColourTemp?.add_lit_effect, (o) => {
    return o.add_beam_angle;
  });

  /* Rearranging and filtering the beam angles for client requirement  */
  const beamAngleOrder = [
    'ultraspot',
    'spot',
    'narrow',
    'medium',
    'wide',
    'flood',
    'wash',
    'crisp',
    'elliptical linear',
    'elliptical oval',
  ];

  const rearrangedBeamAngles = beamAngleOrder.map((angleToFind) => {
    if (
      angleToFind === 'Ultra Spot' ||
      angleToFind === 'ultraspot' ||
      angleToFind === 'ultra spot' ||
      angleToFind === 'Ultra Spot'
    ) {
      return find(beamAnglesFlat, (o) => {
        return o.beam_angle.toLowerCase() === 'ultraspot';
      });
    } else {
      return find(beamAnglesFlat, (o) => {
        return o.beam_angle.toLowerCase() === angleToFind.toLowerCase();
      });
    }
  });

  const filteredRearrangedBeamAngles = filter(rearrangedBeamAngles, (o) => {
    return o !== undefined;
  });

  const selectedBeamAngle = find(beamAnglesFlat, (o) => {
    if (
      beamAngle === 'Ultra Spot' ||
      beamAngle === 'ultraspot' ||
      beamAngle === 'ultra spot' ||
      beamAngle === 'Ultra Spot'
    ) {
      return o.beam_angle === 'Ultraspot';
    } else {
      return o.beam_angle === beamAngle;
    }
  });

  const beamToDisplay =
    data.beam_angles.description +
    ', ' +
    selectedBeamAngle?.beam_width_fwhm +
    ', ' +
    selectedBeamAngle?.luminaire_lumens.replace('lm', ' luminaire lumens');

  const orderedDescriptions = [
    //data.fitting_codes.description,
    data.bezel_colours.description !== ''
      ? data.bezel_colours.description + ', ' + data.baffle_colours.description
      : null,
    data.engine_type.description,
    data.colour_temperature.description + ', ' + beamToDisplay,
    ...optiondescriptions,
    data.drivers.description,
    cri + ' CRI',
    circuit_power + ' Circuit Power',
    '2-step MacAdam ellipse',
  ];

  const descList = orderedDescriptions.map((description) => (
    <p key={description}>{description}</p>
  ));

  const pdfRef = useRef();

  const { isCircular, circularAttributes } = useSelector(
    (state) => state.product.data
  );

  useEffect(() => {
    if (toggleSpec) {
      document.body.classList.add('overflow-hidden');
    }

    if (smallScreen) {
      setToggleSpec(false);
    }

    if (closeSpec || !toggleSpec || smallScreen) {
      document.body.classList.remove('overflow-hidden');
    }
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [toggleSpec, closeSpec, smallScreen]);

  const specClasses = classNames({
    [`${styles['quick-spec']}`]: true,
    [`${styles['is-open']}`]: toggleSpec,
    [`${styles.close}`]: closeSpec,
  });

  const quicksheetHeaders = {
    beam_angle: 'Beam angle',
    beam_width_fwhm: 'Beam width FWHM',
    initial_lumens: 'Initial lumens',
    engine_lumens: 'Engine lumens',
    luminaire_lumens: 'Luminaire Lumens',
  };

  const beamTableContent = [...Object.keys(quicksheetHeaders)].map(
    (key, index) => {
      const allBeamAngles = filteredRearrangedBeamAngles.map((beamAngle) => {
        if (
          selectedBeamAngle.beam_angle?.toLowerCase() ===
          beamAngle.beam_angle.toLowerCase()
        ) {
          const firstRow = index === 0 ? styles['first-row'] : '';
          const lastRow =
            index === [...Object.keys(quicksheetHeaders)].length - 1
              ? styles['last-row']
              : '';

          return (
            <td
              className={
                styles['selected-beam-angle'] + ' ' + firstRow + ' ' + lastRow
              }
              key={beamAngle['beam_angle'] + '-' + key + '-' + beamAngle[key]}
            >
              <p>{beamAngle[key]}</p>
            </td>
          );
        } else {
          return (
            <td
              key={beamAngle['beam_angle'] + '-' + key + '-' + beamAngle[key]}
            >
              {beamAngle[key]}
            </td>
          );
        }
      });

      return (
        <tr key={HEADERS[key]}>
          <td className={styles['keys']}>{HEADERS[key]}</td>
          {allBeamAngles}
        </tr>
      );
    }
  );

  const beamTable = <tbody>{beamTableContent}</tbody>;

  const { pageTemplate } = useSelector((state) => state.product.data);

  const handleQuicksheet = (filename) => {
    const pdfDiv = document.getElementById('pdf');
    const circularBubbles = circularAttributes
      ? Object.keys(circularAttributes).map((key) => {
          if (key !== 'background') {
            return (
              <div className={styles['bubbles']}>
                {' '}
                <p>
                  {circularAttributes[key]}
                  {'% '} <span> {key}</span>
                </p>
              </div>
            );
          } else {
            return null;
          }
        })
      : '';

    ReactDOM.render(
      <div ref={pdfRef} className={styles['quicksheet']}>
        <div className={styles['title-bar']}>
          <h1>orluna | quicksheet</h1>
          <h2>
            <img src={circularProductLogoWhite} alt="Circular Product" />
          </h2>
        </div>
        <div className={styles['code-container']}>
          <CodeAndLabels
            data={data}
            toggleSpec={true}
            pageTemplate={pageTemplate}
          />
        </div>

        <p></p>
        <div className={styles['top-details']}>
          <div className={styles['main-image-container']}>
            {pageTemplate !== 'led' && (
              <ul>
                <li>Bezel: {data.bezel_colours.label}</li>
                <li>Baffle: {data.baffle_colours.label}</li>
              </ul>
            )}

            <img src={mainImage} alt={'Orluna -' + productName} />
          </div>
          <div className={styles['line-drawing-container']}>
            {
              <img
                src={drawingOne}
                alt={'Orluna -' + productName + 'line drawing'}
              />
            }
          </div>
          <div className={styles['product-descriptions']}>
            <div>
              <h3>{data.fitting_codes.description} </h3>
              <h4 className={styles['intro-text']}>{productDescription}</h4>
              {descList}
            </div>
          </div>
        </div>
        <table className={styles['beam-table']}>{beamTable}</table>
        <div className={styles['lower-boxes']}>
          <div className={styles['circular']}>
            {isCircular && circularBubbles}
          </div>
          <div className={styles['manufacturer']}>
            <p>
              <br />
              Ripa Subhan / info@orluna.com / 00 44 (0) 1923 889712 /<br />
              Orluna LED Technologies Limited, Unit A, Chiltern Park Industrial
              Estate, Dunstable, <br /> LU5 4LT United Kingdom
            </p>
          </div>
        </div>
      </div>,
      pdfDiv
    );

    createQuicksheet(pdfRef.current, filename, mainImage);

    unmountComponentAtNode(pdfDiv);
  };

  return (
    <>
      {!toggleSpec && (
        <SpecBar
          data={data}
          toggleSpec={toggleSpec}
          setToggleSpec={setToggleSpec}
          setCloseSpec={setCloseSpec}
          productName={productName}
        />
      )}
      <div className={specClasses}>
        <div className={styles.wrapper}>
          <SpecBar
            data={data}
            toggleSpec={toggleSpec}
            setToggleSpec={setToggleSpec}
            setCloseSpec={setCloseSpec}
            productName={productName}
          />
          <div className={styles['info-wrapper']}>
            <div className={styles['code-container']}>
              <CodeAndLabels
                data={data}
                toggleSpec={toggleSpec}
                pageTemplate={pageTemplate}
              />
            </div>
            <Summary
              data={data}
              mainImage={mainImage}
              drawingOne={drawingOne}
              drawingTwo={drawingTwo}
              handleQuicksheet={handleQuicksheet}
              productName={productName}
            />

            <div className={styles['beam-table-container']}>
              <table className={styles['beam-table']}>{beamTable}</table>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
};
