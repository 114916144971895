import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { cleanSpecCode } from 'redux/actions';
import { setActiveVariant } from 'redux/actions/product';
import { thumbExtensionCreator } from 'modules/thumb-extension-creator';
import PropTypes from 'prop-types';
import { LazyImage } from 'components/lazy-image';
import { EngineSelector } from 'components/engine-selector';
import { Loading } from 'components/loading';
import tick from 'assets/images/tick.svg';
import styles from './style.module.scss';

export const variantSectionRef = React.createRef();

export const SelectVariant = ({
  productName,
  variants,
  activeVariant,
  isCircular,
  circularAttributes,
  pageTemplate,
}) => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (activeVariant !== null && activeVariant !== undefined) {
      setActive(activeVariant);
      setLoading(false);
    }
  }, [activeVariant, variants]);

  const handleClick = (variant) => {
    dispatch(setActiveVariant(variant));
    dispatch(cleanSpecCode());
  };

  const variantClasses = (variant) => {
    return classNames({
      [`${styles.active}`]: active.name === variant.name,
    });
  };

  if (loading) return <Loading />;

  return (
    <>
      {variants.length > 1 && pageTemplate !== 'led' && (
        <section className={styles['select-variant']} ref={variantSectionRef}>
          <h2>select variant</h2>
          <div className={styles.wrapper}>
            {variants.map((variant, i) => (
              <div
                key={variant + i}
                className={variantClasses(variant)}
                onClick={() => handleClick(variant)}
              >
                <img className={styles.tick} src={tick} alt="tick icon" />
                <LazyImage
                  thumbnailSrc={thumbExtensionCreator(
                    variant.thumbnail,
                    '-thumb'
                  )}
                  src={variant.thumbnail}
                  alt="variant thumbnail"
                />
                <p>{variant.name}</p>
              </div>
            ))}
          </div>
        </section>
      )}

      <EngineSelector
        productName={productName}
        singleVariant={variants.length < 2}
        options={active.engines}
        product={active}
        isCircular={isCircular}
        circularAttributes={circularAttributes}
        pageTemplate={pageTemplate}
        fittingCodes={variants.map((variant) => ({
          value: variant.fitting_code.value,
          label: variant.name,
        }))}
      />
    </>
  );
};

SelectVariant.propTypes = {
  productName: PropTypes.string,
  variants: PropTypes.array.isRequired,
  activeVariant: PropTypes.object,
};
