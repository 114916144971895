import React from 'react';
//import { Circular } from 'components/icons';
import styles from './style.module.scss';

export const TooltipCircular = () => {
  return (
    <span className={styles.tooltip}>
      <span>Circular Product</span>
      <span>
        <img
          src="https://assets.orluna.com/uploads/2022/02/tooltip-inifinity-loop.png"
          alt=""
        />
      </span>
    </span>
  );
};
