import {
  PROMOTIONAL_MODULE_REQUEST,
  PROMOTIONAL_MODULE_RECEIVED,
} from 'redux/actions/promotional-module';

const initialState = {
  hasError: false,
  promotionalModule: [],
  promotionalModuleLoading: false,
};

export default function promotionalModule(state = initialState, action) {
  switch (action.type) {
    case PROMOTIONAL_MODULE_REQUEST:
      return Object.assign({}, state, {
        hasError: false,
        promotionalModule: [...state.promotionalModule],
        promotionalModuleLoading: true,
      });
    case PROMOTIONAL_MODULE_RECEIVED:
      return Object.assign({}, state, {
        hasError: false,
        promotionalModule: action.promotionalModule,
        promotionalModuleLoading: false,
      });
    default:
      return state;
  }
}
