import React from 'react';
import { HEADERS, LEDHEADERS } from 'constants/spec-code-downloads';
import { getValueFromObjectValue } from 'modules/get-value-from-object-value';
import keys from 'lodash/fp/keys';
import styles from './style.module.scss';

export const CodeAndLabels = ({ data, toggleSpec, pageTemplate }) => {
  const headers = pageTemplate === 'led' ? LEDHEADERS : HEADERS;

  return (
    <>
      {keys(data).map((item, i) => {
        let addDash = i === 0 || i === 1 || i === 4 || i === 5 || i === 6;
        let codes = getValueFromObjectValue(data[item], 'value');
        let labels = getValueFromObjectValue(data[item], 'label');

        if (
          pageTemplate === 'led' &&
          ((codes === '' && labels === '') || codes === 'h')
        ) {
          return false;
        } else {
          addDash =
            i === 0 || i === 1 || i === 2 || i === 3 || i === 4 || i === 5;
          return (
            <div key={item + i}>
              <p className={styles.header}>{headers[item]}</p>
              <span className={styles.code}>
                {codes}
                {addDash && <span> - </span>}
              </span>
              {toggleSpec && (
                <ul>
                  {Array.isArray(labels) ? (
                    labels.map((label, index) => (
                      <li key={label + index}>{label}</li>
                    ))
                  ) : (
                    <li>{labels}</li>
                  )}
                </ul>
              )}
            </div>
          );
        }
      })}
    </>
  );
};
