export const DICTIONARY = {
  fitting_codes: {
    cvt: 'Adjustable',
    cmb: 'Adjustable',
    dr: 'Adjustable',
    dir: 'Adjustable',
    riv: 'Adjustable',
    tyr: 'Adjustable',
    fd: 'Adjustable',
    dlr: 'Adjustable',
    mir: 'Adjustable',
    one: 'Adjustable',
    nkk: 'Adjustable',
    cdo: 'Adjustable',
    sur: 'Adjustable',
    ti: 'Adjustable',
    tre: 'Adjustable',
    jde: 'Adjustable',
    cn: 'Fixed',
    cvf: 'Fixed',
    cvrf: 'Fixed',
    cbx: 'Fixed',
    df: 'Fixed',
    dif: 'Fixed',
    tyf: 'Fixed',
    fdx: 'Fixed',
    fi: 'Fixed',
    mif: 'Fixed',
    onx: 'Fixed',
    suf: 'Fixed',
    trx: 'Fixed',
    jdx: 'Fixed',
    dlr2: 'Twin',
    nk2: 'Twin',
    cd2: 'Twin',
    dir2: 'Twin',
    ri2: 'Twin',
    alo: 'Adjustable',
    af: 'Fixed',
    ap: 'Fixed',
    ab: 'Adjustable',
    ai: 'Adjustable',
    ar: 'Adjustable',
    a2: 'Adjustable',
    at: 'Adjustable',
    cel: 'Adjustable',
    cef: 'Fixed',
    cer: 'Fixed',
    crl: 'Fixed',
    cue: 'Fixed',
    clp: 'Fixed',
    roc: 'Fixed',
    rlp: 'Fixed',
    mu: 'Fixed',
    du: 'Fixed',
    els: 'Elia Surface',
    elr: 'Elia Recessed',
    elp: 'Elia Plaster-in',
    vir: 'Adjustable',
    vi2: 'Twin',
    ty2: 'Twin',
    cb2: 'Twin',
    fd2: 'Twin',
    tre2: 'Twin',
    fir: 'Adjustable',
    otr: 'Adjustable',
    otx: 'Fixed',
    onem: 'Adjustable',
    onxm: 'Fixed',
    cmbm: 'Adjustable',
    cbxm: 'Fixed',
    fdm: 'Adjustable',
    fdxm: 'Fixed',
    trem: 'Adjustable',
    trxm: 'Fixed',
    surm: 'Adjustable',
    sufm: 'Fixed',
    cvm: 'Adjustable',
    cvxm: 'Fixed',
    tp: 'Fixed',
    tt: 'Fixed',
    tm: 'Fixed',
    tc: 'Fixed',
    tz: 'Fixed',
    atp: 'Fixed',
    att: 'Fixed',
    atm: 'Fixed',
    atc: 'Fixed',
    atz: 'Fixed',
    vs: 'Fixed',
    vt: 'Fixed',
    eli: 'Fixed',
    ori: 'Fixed',
    art: 'Fixed',
    frm: 'Fixed',
    var: 'Fixed',
    mt: 'Fixed',
  },
  beam_angles: {
    m: 'Medium',
    w: 'Wide',
    f: 'Flood',
    h: 'Wash',
    u: 'UltraSpot',
    s: 'Spot',
    n: 'Narrow',
    c: 'Crisp',
    l: 'EllipticalLinear',
    o: 'EllipticalOval',
    a: 'Asymmetrical',
    r: 'Floorwash',
  },
  drivers: {
    m: 'Mains-Dimmable',
    d: 'DALI',
    '0v': '0-10V',
    n: 'Non-Dimmable',
    x: 'Driver',
  },
};

export const SPEC_CODE_EMERGENCY = 'Emergency';
export const SPEC_CODE_E = 'e';

export const SHOULD_FILTER = [
  'add_psu',
  'add_1800_3000k',
  'add_2700k',
  'add_3000k',
  'add_1800_4000k',
  'add_2700_6500k',
  'add_luminaire_photometric_reports',
];

/**
 * custom fields / product detail / specification code / bazel colours and baffle colours
 */
export const SPEC_CODE_COLOURS = {
  w: '#f1ece1',
  wb: '#f1ece1',
  d: '#ecece7',
  db: '#ecece7',
  b: '#0e0e10',
  bb: '#0e0e10',
  g: '#daae63',
  mb: '#daae63',
  j: '#987f60',
  jb: '#987f60',
  k: '#a1a1a3',
  kb: '#a1a1a3',
  ss: '#aaa9ad',
  l: '#bfbdbd',
  lb: '#bfbdbd',
  z: '#979797',
  zb: '#979797',
  c: '#979797',
  cb: '#979797',
  a: '#302c29',
  ab: '#302c29',
  f: '#7f8689',
  fb: '#7f8689',
  p: '#dbc592',
  pb: '#dbc592',
  qb: '#d8d9e0',
};

// used on spec-code and quick-spec
export const HEADERS = {
  engine_type: 'engine',
  fitting_codes: 'fitting',
  colour_temperature: 'colour temp',
  drivers: 'driver',
  beam_angles: 'beam',
  bezel_colours: 'bezel',
  baffle_colours: 'baffle',
  options: 'options',
};

export const LEDHEADERS = {
  engine_type: 'engine',
  fitting_codes: 'fitting',
  colour_temperature: 'colour temp',
  //drivers: 'driver',
  //beam_angles: 'beam',
  //bezel_colours: 'bezel',
  //baffle_colours: 'baffle',
  options: 'options',
};

export const SPEC_CODE_DEFAULT_COLOUR = '#979797';

export const DISPLAYED_EXTENSIONS = ['ldt', 'dxf', 'ies'];

export const DOWNLOAD_ADD_EPD = 'add_epd';
export const DOWNLOAD_ADD_1800_3000 = 'add_1800_3000k';
export const DOWNLOAD_ADD_1800_4000 = 'add_1800_4000k';
export const DOWNLOAD_ADD_2700_6500 = 'add_2700_6500k';
export const DOWNLOAD_ADD_2700 = 'add_2700k';
export const DOWNLOAD_ADD_3000 = 'add_3000k';
export const DOWNLOAD_ADD_IMAGE = 'add_image_pack';
export const DOWNLOAD_ADD_PSU = 'add_psu';
export const DOWNLOAD_ADD_DATASHEETS = 'add_datasheets';
export const DOWNLOAD_ADD_DRAWINGS = 'add_diagrams_and_line_drawings';
export const DOWNLOAD_ADD_INSTALLATION =
  'add_installer_and_contractor_information';
export const DOWNLOAD_ADD_PHOTO_REPORTS = 'add_luminaire_photometric_reports';

export const DOWNLOAD_HEADERS = {
  [DOWNLOAD_ADD_EPD]: 'EPD',
  [DOWNLOAD_ADD_1800_3000]: 'Photometric file',
  [DOWNLOAD_ADD_1800_4000]: 'Photometric file',
  [DOWNLOAD_ADD_2700_6500]: 'Photometric file',
  [DOWNLOAD_ADD_2700]: 'Photometric file',
  [DOWNLOAD_ADD_3000]: 'Photometric file',
  [DOWNLOAD_ADD_PHOTO_REPORTS]: 'Photometric reports',
  [DOWNLOAD_ADD_DATASHEETS]: 'Datasheet',
  [DOWNLOAD_ADD_IMAGE]: 'Image pack',
  [DOWNLOAD_ADD_PSU]: 'Power supplies',
  [DOWNLOAD_ADD_INSTALLATION]: 'Installation sheet',
  [DOWNLOAD_ADD_DRAWINGS]: 'DXF Drawings',
};

export const NO_DATA_HEADERS_LEFT = [
  'EPD',
  'Datasheet',
  'Photometric file',
  'Photometric file',
  'Photometric Report',
  'DXF Drawing 1',
  'DXF Drawing 2',
  'Installation sheet',
];

export const NO_DATA_HEADERS_RIGHT = ['Image Pack', 'Power supplies'];
