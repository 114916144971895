import React from 'react';
import styles from './style.module.scss';
import { Button } from 'components/buttons';
import alerticon from '../../assets/images/alert-icon.png';

function AlertDialog({
  toggleFunc,
  title,
  button1,
  btn1callback,
  button2,
  bt2callback,
  children,
}) {
  return (
    <>
      <div className={styles['overlay']}>
        <div className={styles['alert-dialog']}>
          <div style={{ backgroundImage: 'url(' + alerticon + ')' }}>
            <div className={styles['content']}>
              <h4>{title}</h4>
              <p>{children}</p>
              <div>
                <Button
                  size="default"
                  theme="dark-transparent"
                  label={button1}
                  handleClick={() => {
                    btn1callback();
                    toggleFunc(false);
                  }}
                />
                <Button
                  size="default"
                  theme="dark"
                  label={button2}
                  handleClick={() => {
                    toggleFunc(false);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AlertDialog;
