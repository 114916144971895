import React from 'react';
import Select, { components } from 'react-select';
import { useSelector } from 'react-redux';
import {
  HEADERS,
  SPEC_CODE_DEFAULT_COLOUR,
} from 'constants/spec-code-downloads';
import { KEY_BAFFLE_COLOURS, KEY_BEZEL_COLOURS } from './constants';
import { customStyles } from './custom-styles';
import { TooltipSpecCode } from 'components/tooltip-spec-code';
import styles from './style.module.scss';

export const SelectOption = ({
  header,
  options,
  isMulti,
  isDisabled,
  handleChange,
  value,
  tooltips,
  stainlessSteelDefault,
  nonfirst,
  ogheader,
  premiumBrassAvailability,
}) => {
  const { Option, Control } = components;
  const productFamily = useSelector((state) => state.product.data.family);
  const isBarrelRod = useSelector((state) => state.product.data.is_barrel_rod);
  const colorOption = (color, border) => (
    <span
      style={{
        background: color,
        border: `1px solid ${border}`,
        display: 'inline-block',
        height: 24,
        width: 24,
        marginRight: 13,
      }}
    />
  );

  const imageOption = (image, border) => (
    <span
      style={{
        border: `1px solid ${border}`,
        display: 'inline-block',
        height: 24,
        width: 24,
        marginRight: 13,
        backgroundSize: 'cover',
        backgroundImage: 'url("' + image + '")',
      }}
    />
  );

  const premiumBrassLabel = (label) => {
    if (label && label !== 'available') {
      return (
        <span
          style={{
            backgroundColor: '#6a6d84',
            borderRadius: 50,
            padding: '5px 10px',
            marginLeft: '15px',
            color: 'white',
            textTransform: 'lowercase',
          }}
        >
          {label === '100'
            ? 'special order'
            : label === 'summer'
            ? 'Due Summer 2024'
            : ''}
        </span>
      );
    } else if (label && label === 'available') {
      return (
        <span
          style={{
            backgroundColor: '#6a6d84',
            borderRadius: 50,
            padding: '5px 10px',
            marginLeft: '15px',
            color: 'white',
            textTransform: 'lowercase',
          }}
        >
          {'available now'}
        </span>
      );
    }
    return null;
  };

  const CustomOption = (props) => {
    const hasColour = props.data.colour !== undefined;
    const hasImage = props.data['thumb-image'] !== undefined;
    const addColor = props.data.colour !== '';
    const addImage = props.data['thumb-image'] !== '';
    const addStd = stainlessSteelDefault && props.data.value === 'ss';

    if (hasImage && addImage) {
      return (
        <Option {...props}>
          {hasImage && imageOption(props.data['thumb-image'])}
          {props.data.label}
          {addStd ? ' (std)' : ''}
          {premiumBrassAvailability &&
            premiumBrassLabel(premiumBrassAvailability)}
        </Option>
      );
    }
    return (
      <Option {...props}>
        {hasColour && addColor && colorOption(props.data.colour)}
        {/* border only  */}
        {hasColour && !addColor && colorOption('', SPEC_CODE_DEFAULT_COLOUR)}
        {props.data.label}
        {addStd ? ' (std)' : ''}
        {premiumBrassAvailability &&
          premiumBrassLabel(premiumBrassAvailability)}
      </Option>
    );
  };

  const CustomControl = (props) => {
    header =
      productFamily.find((element) => element === 'surface-track') &&
      header === 'bezel' &&
      isBarrelRod
        ? 'barrel & rod'
        : header;

    const addToolTip =
      (header === HEADERS[KEY_BEZEL_COLOURS] && tooltips[header]?.display) ||
      (header === HEADERS[KEY_BAFFLE_COLOURS] && tooltips[header]?.display) ||
      (header === 'barrel & rod' && tooltips[header]?.display);

    if (!nonfirst && ogheader) {
      return (
        <div>
          <p className={styles['og-header']}>{ogheader}</p>
          {!nonfirst && <Control {...props} />}
          {<p>{header + (isMulti ? ' (multi select)' : '')}</p>}
          {addToolTip && (
            <TooltipSpecCode
              title={header}
              headings={tooltips[header].headings}
            />
          )}
        </div>
      );
    }

    return (
      <div className={nonfirst && styles['group-headings']}>
        {<p>{header + (isMulti ? ' (multi select)' : '')}</p>}
        {addToolTip && (
          <TooltipSpecCode
            title={header}
            headings={tooltips[header].headings}
          />
        )}
        {!nonfirst && <Control {...props} />}
      </div>
    );
  };

  const CustomValue = ({ data }) => {
    if (data.value === undefined) return null;
    if (Array.isArray(data.value)) {
      return data.value.map(({ value }) => value).join('');
    }
    return data.value;
  };

  return (
    <Select
      id={header}
      value={value}
      menuIsOpen={true}
      menuShouldScrollIntoView={false}
      captureMenuScroll={false}
      options={options}
      isOptionDisabled={(option) => option.disabled}
      components={{
        Control: CustomControl,
        Option: CustomOption,
        SingleValue: CustomValue,
        MultiValue: CustomValue,
      }}
      styles={customStyles}
      isMulti={isMulti}
      isDisabled={isDisabled}
      hideSelectedOptions={false}
      isSearchable={false}
      placeholder="select below"
      onChange={(selection) => handleChange(selection)}
    />
  );
};
