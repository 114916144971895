import React from 'react';

export const RightIcon = (props) => (
  <svg data-name="Layer 1" viewBox="0 0 38 38" {...props}>
    <g data-name="np down 1137390 000000">
      <circle cx={19} cy={19} r={19} fill="#f1f1f1" />
      <path
        style={{
          transformBox: 'fill-box',
          transformOrigin: 'center',
          transform: 'rotate(90deg)',
        }}
        fill="#6a6d84"
        d="M27 20.41L25.39 22 19 15.29 12.62 22 11 20.41 19 12l8 8.41z"
      />
    </g>
  </svg>
);
