import React from 'react';
import styles from './style.module.scss';
import { useSelector } from 'react-redux';

function LEDTable() {
  const { ledComponentsTable } = useSelector((state) => state.product.data);
  const tableContents = ledComponentsTable.map((componentType) => {
    let componentName = componentType['component_type'].component_name;
    let componentDetails = componentType['component_type'].component_details;
    let componentRows = componentDetails.map((component, index) => {
      return (
        <tr>
          {index === 0 && (
            <td
              className={styles['component-name']}
              rowSpan={componentDetails.length}
            >
              <p>{componentName}</p>
            </td>
          )}
          <td>{component.component_description}</td>
          <td>{component.component_code}</td>
        </tr>
      );
    });
    return (
      <>
        {componentRows}
        <tr className={styles['spacer-row']}>
          <td className={styles['first-td']}></td>
          <td></td>
          <td></td>
        </tr>
      </>
    );
  });
  return (
    <section className={styles['led-components-section']}>
      <h3>LED Strip accessory codes</h3>
      <p>
        Listed below are the accessory options available for our LED strip
        products.
      </p>

      <table className={styles['led-table']}>
        <tr>
          <th></th>
          <th>DESCRIPTION</th>
          <th>CODE</th>
        </tr>
        <tr className={styles['spacer-row']}>
          <td className={styles['first-td']}></td>
          <td></td>
          <td></td>
        </tr>
        {tableContents}
      </table>
    </section>
  );
}

export default LEDTable;
