import STATEMENTS from 'components/spec-code-new/statements';

export const applySpecCodeStatements = (
  product,
  codeGroup,
  data,
  optionsValues,
  callbackObj,
  isAlertOpen
) => {
  let result = data;

  STATEMENTS.forEach(({ check, execute, warning }) => {
    if (check(product, codeGroup, optionsValues)) {
      if (warning && result && !isAlertOpen) {
        let message = warning();
        const args = callbackObj[message].args;
        let cb = callbackObj[message].func;
        cb(true, args.title, args.button1, args.button2, args.message);
        console.log('hello');
      }
      result = execute(result);
    }
  });
  return result;
};
