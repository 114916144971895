import React, { useEffect, useRef, useContext } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import { Button } from 'components/buttons';
import { SIZE_BIG } from 'constants/sizes';
import { THEME_DARK } from 'constants/color-theme';
import { LeftIcon } from 'components/icons';
import { RightIcon } from 'components/icons';
import specCodeContext from 'contexts/spec-code';
import styles from './style.module.scss';

function slideMapping(arrayToParse, attr) {
  if (attr === 'name') {
    return arrayToParse.map((obj) => {
      return <p className={styles['finish-name']}>{obj.name}</p>;
    });
  }
  return arrayToParse.map((obj) => {
    return <img alt={obj.name} src={obj.image} key={obj.name} />;
  });
}

function PromotionalModule() {
  const sliderBG = React.useRef(null);
  const sliderFinish = React.useRef(null);
  const videoOverlay = useRef(0);
  const promotionalModule = useRef(0);
  const enterBtn = useRef(0);
  const backToVideoBtn = useRef(0);
  const backgroundVideo = useSelector(
    (state) => state.promotionalModule.promotionalModule.background_video.url
  );
  const finishGroups = useSelector(
    (state) => state.promotionalModule.promotionalModule.finish_group
  );
  const productType = useSelector((state) => state.product.data.type);
  const selectedGroup = finishGroups.filter((obj) => {
    return obj.finish_grouping_name === productType[0];
  });

  const finishes = selectedGroup[0].finish;
  const PRODUCTS = finishes.map((finish) => {
    return {
      name: finish.finish_name,
      image: finish.image.url,
    };
  });

  const specCodeRef = useContext(specCodeContext);
  const slides = slideMapping(PRODUCTS); // Will change dependant upon what
  const slideNames = slideMapping(PRODUCTS, 'name');
  const backgroundSettings = {
    arrows: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const navSettings = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
  };
  useEffect(() => {
    enterBtn.current.addEventListener('mouseenter', (e) => {
      videoOverlay.current.classList.add(styles['invisible']);

      setTimeout(() => {
        videoOverlay.current.classList.add(styles['hidden']);
      }, 500);
    });
    /*
    promotionalModule.current.addEventListener('mouseleave', (e) => {
      videoOverlay.current.classList.remove(styles['hidden']);
      videoOverlay.current.classList.remove(styles['invisible']);
    });
    */

    backToVideoBtn.current.addEventListener('click', (e) => {
      videoOverlay.current.classList.remove(styles['hidden']);
      videoOverlay.current.classList.remove(styles['invisible']);
    });
  }, []);

  return (
    <>
      <div className={styles['promotional-module']} ref={promotionalModule}>
        <p ref={backToVideoBtn} className={styles['back-to-video']}>
          {' '}
          {'< back to video '}{' '}
        </p>
        <div className={styles['video-overlay']} ref={videoOverlay}>
          <video playsInline autoPlay muted loop>
            <source src={backgroundVideo} type="video/mp4" />
          </video>
          <div className={styles['details']}>
            <h3>stunning new finishes</h3>
            <p>
              Our premium finishes, each made of solid brass, have been aged to
              perfection and frozen in time. Deliberately crafted so that your
              whole world, can be beautifully lit.
            </p>
            <span ref={enterBtn}>
              <Button
                stylesClass={styles['colour-btn']}
                size={SIZE_BIG}
                theme={THEME_DARK}
                label="explore our finishes"
              />
            </span>
          </div>
        </div>
        <div className={styles['gradient-overlay']}></div>
        <Slider
          ref={sliderBG}
          className={'background-slide ' + styles['background-slide']}
          {...backgroundSettings}
        >
          {slides}
        </Slider>
        <div className={styles['navigations']}>
          <h3>explore our finishes</h3>
          <div className={styles['nav-holder']}>
            <button
              className={styles['prev-button']}
              onClick={() => {
                sliderFinish?.current?.slickPrev();
                sliderBG?.current?.slickPrev();
              }}
            >
              <LeftIcon />
            </button>
            <Slider ref={sliderFinish} className="nav-slide" {...navSettings}>
              {slideNames}
            </Slider>
            <button
              className={styles['next-button']}
              onClick={() => {
                sliderFinish?.current?.slickNext();
                sliderBG?.current?.slickNext();
              }}
            >
              <RightIcon />
            </button>
          </div>
          <Button
            stylesClass={styles['colour-btn']}
            size={SIZE_BIG}
            theme={THEME_DARK}
            label="configure product"
            handleClick={() => {
              specCodeRef.current.scrollIntoView({ behavior: 'smooth' });
            }}
          />
        </div>
      </div>
    </>
  );
}

export { PromotionalModule };
