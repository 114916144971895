export const descriptions = {
  or: 'General use, 98 CRI',
  nt: 'T1 - 1800K to 4000K, T2 - 2700K to 6500K',
  nh: 'Our highest quality light source, Flattering Provence light, 98 CRI / Rf98 / Rg100',
  oh: 'General use, 98 CRI',
  wd: 'Warms gently as it dims, 3000K warming to 1800K, 98 CRI',
  lo: 'Low height for low voids, 98 CRI',
  ho: 'Designed for maximum light output, 85 CRI',
  100: 'Designed for low level lighting, Low power consumption with high lumen impact, 90 CRI',
  nl: 'Our highest quality light source, Flattering Provence light, 98 CRI / Rf98 / Rg100',
  ne: 'Our highest quality light source, Flattering Provence light, 98 CRI / Rf98 / Rg100',
  oe: 'General use, 98 CRI',
  nu: 'origin natural ultra output',
  ou: 'origin ultra output',
  zh: '120 LL/cw  (part L & LEED), 93 CRI',
};
