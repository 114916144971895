import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

export const createQuicksheet = async (contents, filename, mainImage) => {
  if (contents && contents !== 'data:,') {
    const canvas = await html2canvas(contents, {
      logging: true,
      letterRendering: 1,
      allowTaint: false,
      useCORS: true,
    });
    const data = canvas.toDataURL('image/jpeg');

    const pdf = new jsPDF('l', 'mm', [297, 210]);
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    // pdf.addImage(mainImage, 'JPEG', 0, 0, pdfWidth, pdfHeight);
    pdf.addImage(data, 'JPEG', 0, 0, pdfWidth, pdfHeight);
    pdf.output('datauristring');
    pdf.save(filename + '.pdf');
  }
};
